<script setup lang="ts">
import { useDisplay } from "vuetify";
import type { ISlide } from "~/lib/slide";
import { clinicImages, slider } from "@/cms/gallery";
import { reviewList, specializationList, staffList } from "~/state/state";
import type { IReview } from "~/lib/review";
definePageMeta({ locale: "home" });

const SpecializationCard = defineAsyncComponent(() => import("@/components/specializationCard.vue"));
const AdvertisementSection = defineAsyncComponent(() => import("@/components/advertisement/section.vue"));
const HowToBookSection = defineAsyncComponent(() => import("@/components/howToBook/section.vue"));
const ReviewsSection = defineAsyncComponent(() => import("@/components/reviews/section.vue"));
const ContactSection = defineAsyncComponent(() => import("@/components/contact/section.vue"));
const CompaniesSection = defineAsyncComponent(() => import("@/components/companies/section.vue"));
const SectionSlider = defineAsyncComponent(() => import("@/components/sectionSlider.vue"));

const { mdAndUp, lgAndUp } = useDisplay();

const slides = computed<ISlide[]>(() => slider.filter((s) => s.heroSlider));
const reviews = computed<IReview[]>(() => reviewList.getElements());

const content = computed(() => {
    return specializationList.getElementsWithoutContent().map((el) => {
        return {
            iconName: el.avatar,
            title: el.name,
            text: el.description,
            to: `/specialization/${stringToHash(el.name)}/${el.id}`
        };
    });
});

const title = "Allmedica Surgery in Poland";
const description =
    "Welcome to Allmedica Surgery in Poland, a medical clinic located outside of Cracow. We specialize in serving international patients and offer a comprehensive range of surgeries, including Plastic Surgery, Bariatric Weight Loss Surgery, Orthopaedic Surgery, General Surgery, and Gynaecological Surgery. Our commitment to fair pricing, expedited services, and unwavering standards of quality and professionalism ensures comprehensive care. As a trusted destination for medical tourism, we warmly welcome patients from all corners of the globe who seek the highest standards of medical expertise and technology.";
const keywords =
    "Allmedica, medical services, plastic surgery, bariatric surgery, orthopedic surgery, general surgery, gynaecological surgery, healthcare, patient care";
</script>
<template>
    <Seo :title="title" :description="description" :keywords="keywords">
        <NuxtLayout>
            <section class="hero-section">
                <div>
                    <HeroDecorationSlider :images="slides" />
                </div>
                <VRow no-gutters align="end" align-sm="center" justify="center" justify-sm="start" class="content">
                    <VCol cols="auto">
                        <div class="d-flex align-end justify-center justify-sm-start" style="pointer-events: none">
                            <VCard variant="flat" color="transparent" elevation="0" class="text-content-wrapper text-center text-sm-left mb-4">
                                <VCardTitle
                                    class="text-content--title text-purple-dark text-h6 text-sm-h5 text-md-h4 text-lg-h3 text-xl-h2"
                                    style="white-space: wrap"
                                >
                                    <h1>
                                        <DecoratedTitle
                                            class="text-center text-sm-left title-wrapper"
                                            prepend-icon="decorator-1"
                                            prepend-icon-size=".75em"
                                            prepend-icon-position="-90% -50%"
                                            prepend-icon-animation-class="shake"
                                            prepend-icon-animation-origin="bottom right"
                                            append-icon="star"
                                            append-icon-size=".75em"
                                            append-icon-position="100% 50%"
                                            append-icon-animation-class="rotate"
                                            text="Excellence In Care, Tailored&nbsp;For&nbsp;You."
                                        />
                                    </h1>
                                </VCardTitle>
                                <VCardSubtitle class="text-red-lighten-1 text-body-1 text-sm-h6 text-lg-h5 py-2">Global Care, Personal Touch </VCardSubtitle>
                                <VCardText class="d-none d-sm-block text-body-2 text-sm-body-1">{{ description }}</VCardText>
                                <!-- <VCardActions class="justify-center justify-sm-start mt-3">
                                    <AccentBtn
                                        rounded
                                        color="deep-purple-darken-4 text-capitalize"
                                        :min-width="mdAndUp ? '10rem' : '6.25rem'"
                                        :size="mdAndUp ? 'large' : 'default'"
                                        style="pointer-events: all"
                                    >
                                        Explore
                                    </AccentBtn>
                                </VCardActions> -->
                            </VCard>
                        </div>
                    </VCol>
                </VRow>
            </section>
            <SectionWrapper id="specialization" gradient-background full-width>
                <div class="wrapper">
                    <h2 style="max-width: 58rem" class="mx-auto">
                        <DecoratedTitle
                            append-icon="star-shine"
                            append-icon-size="1.2em"
                            append-icon-position="100% 50%"
                            append-icon-animation-class="rotate"
                            text="Every Patient, Every Detail, Every Time – Because We Care!"
                            decorated-phrase="Because We Care!"
                            class="text-center"
                        />
                    </h2>
                    <p style="max-width: 34rem" class="text-center text-body-2 text-md-body-1 mx-auto mt-2 mt-md-7">
                        We are dedicated by a deep-seated commitment to prioritize your well-being every time, without exception.
                    </p>
                    <VRow class="mt-16" :class="{ 'd-flex': lgAndUp, 'd-none': !lgAndUp }">
                        <VCol cols="4">
                            <VRow>
                                <VCol cols="12" class="pa-5">
                                    <SpecializationCard
                                        avatar-size="7rem"
                                        :avatar-image-src="content[0].iconName"
                                        :title="content[0].title"
                                        :text="content[0].text"
                                        :to="content[0].to"
                                    />
                                </VCol>
                                <VCol cols="12" class="pa-5">
                                    <SpecializationCard
                                        avatar-size="7rem"
                                        :avatar-image-src="content[1].iconName"
                                        :title="content[1].title"
                                        :text="content[1].text"
                                        :to="content[1].to"
                                    />
                                </VCol>
                            </VRow>
                        </VCol>
                        <VCol cols="4">
                            <VRow class="fill-height" align="center" justify="start">
                                <VCol cols="12" class="pa-5 text-right" style="height: 15%">
                                    <Svg name="dots" height="15rem" width="15rem" class="move-horizontal"></Svg>
                                </VCol>
                                <VCol cols="12" class="pa-5">
                                    <SpecializationCard
                                        avatar-size="7rem"
                                        :avatar-image-src="content[2].iconName"
                                        :title="content[2].title"
                                        :text="content[2].text"
                                        :to="content[2].to"
                                    />
                                </VCol>
                                <VCol cols="12" class="pa-5">
                                    <h3>
                                        <DecoratedTitle class-name="text-right text-lg-h4" text="Explore a World of Wellbeing" decorated-phrase="Wellbeing" />
                                    </h3>
                                    <p class="text-body-1 text-right mt-4">
                                        Step into a World Where Healthcare Knows No Boundaries: Where Personalized Care, Uncompromising Safety, and Unbeatable
                                        Value Merge. Your Gateway to Superior Medical Care Abroad Starts Now!
                                    </p>
                                </VCol>
                            </VRow>
                        </VCol>
                        <VCol cols="4">
                            <VRow>
                                <VCol cols="12" class="pa-5">
                                    <SpecializationCard
                                        avatar-size="7rem"
                                        :avatar-image-src="content[3].iconName"
                                        :title="content[3].title"
                                        :text="content[3].text"
                                        :to="content[3].to"
                                    />
                                </VCol>
                                <VCol cols="12" class="pa-5">
                                    <SpecializationCard
                                        avatar-size="7rem"
                                        :avatar-image-src="content[4].iconName"
                                        :title="content[4].title"
                                        :text="content[4].text"
                                        :to="content[4].to"
                                    />
                                </VCol>
                            </VRow>
                        </VCol>
                    </VRow>

                    <div :class="{ 'd-block': !lgAndUp, 'd-none': lgAndUp }">
                        <SectionSlider variant="specialization" :interval="4000" :slider-items="content" :height="mdAndUp ? '30rem' : '27rem'" />
                        <h3 class="mt-2 mt-sm-4 mt-md-15">
                            <DecoratedTitle
                                class-name="text-left text-h6 text-sm-h5 text-md-h4 text-md-0"
                                text="Explore a World of Wellbeing"
                                decorated-phrase="Wellbeing"
                            />
                        </h3>
                        <p class="text-body-2 text-lg-body-1 text-left text-xl-right mt-2 mt-lg-4">
                            Step into a World Where Healthcare Knows No Boundaries: Where Personalized Care, Uncompromising Safety, and Unbeatable Value Merge.
                            Your Gateway to Superior Medical Care Abroad Starts Now!
                        </p>
                    </div>
                </div>
            </SectionWrapper>
            <SectionWrapper>
                <h2 style="max-width: 58rem" class="mx-auto">
                    <DecoratedTitle
                        text="Welcome to Allmedica Surgery in Poland located outside of Krakow"
                        decorated-phrase="Allmedica Surgery in Poland"
                        class="text-center"
                    />
                </h2>
                <div class="mt-16 mx-auto" style="max-width: 50rem">
                    <MoviesYouTubeEmbed id="AyerxjbFzRs" title="Welcome to Allmedica Surgery in Poland located outside of Krakow" />
                </div>
            </SectionWrapper>
            <AdvertisementSection />
            <ReviewsSection v-if="reviews.length" :reviews="reviews" />
            <SectionWrapper gradient-background watermark-text="team" full-width>
                <div class="wrapper">
                    <h2 class="text-center mb-7">
                        <DecoratedTitle text="Global Patient Care: Meet Our Team" decorated-phrase="Meet Our Team" />
                    </h2>
                    <VRow no-gutter justify="center">
                        <VCol cols="12" md="8">
                            <p class="text-center">
                                Introducing our distinguished medical team widely recognized across Europe. At Allmedica Surgery in Poland, we bring together a
                                team of experienced doctors and medical staff. Our doctors working with international patients have trained and worked in
                                Europe, Asia, North America, and South America, bringing a high level of expertise and a broad perspective to their practice.
                            </p>
                            <p class="text-center my-7">
                                By combining their international experience with a focus on personalized care, our team offers exceptional medical services
                                tailored to the needs of each patient.
                            </p>
                        </VCol>
                    </VRow>
                </div>
                <ClientOnly>
                    <SectionSlider variant="staff" :interval="4000" :slider-items="staffList.getElementsWithoutContent({ onlyMedical: true })" height="35rem" />
                </ClientOnly>
            </SectionWrapper>
            <HowToBookSection />
            <CompaniesSection />
            <ContactSection />
            <GallerySection :items="clinicImages" text="Take look at our clinic" decorated-phrase="our clinic" />
        </NuxtLayout>
    </Seo>
</template>
<style lang="scss" scoped>
.hero-section {
    min-height: 80vh;
    position: relative;
    overflow: hidden;

    & > div {
        position: absolute;
        inset: 0;
    }

    .content {
        pointer-events: none;
        background-color: hsla(0, 100%, 100%, 0.7);

        @media (width > 1280px) {
            background-color: transparent;
        }

        & > div {
            max-width: 35rem;
            margin: 0 auto;
            width: 93%;

            @media (width > 960px) {
                max-width: 55rem;
            }

            @media (width > 1280px) {
                max-width: 72rem;
            }

            @media (width > 1920px) {
                max-width: 83rem;
            }
        }

        .text-content-wrapper {
            overflow: visible;
            max-width: 35rem;

            .text-content--title {
                overflow: visible;
                line-height: 1;

                .title-wrapper {
                    position: relative;

                    svg {
                        position: absolute;

                        &.prepend {
                            left: 0;
                            top: 0;
                        }

                        &.append {
                            right: 0;
                            bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
</style>
